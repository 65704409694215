<template>
  <div class="logout">
  </div>
</template>

<script>
import { CONSTANTS as AUTH_CONSTANTS, } from '@/store/modules/auth/auth.constants';

export default {
  name: 'LogoutView',
  created() {
    this.$store.dispatch(AUTH_CONSTANTS.ACTIONS.AUTH_LOGOUT);
    this.$router.push({ name: 'login' }, () => {});
  }
};
</script>

<style lang="scss">
</style>
